import { handleActions, combineActions } from 'redux-actions';
import {
  getGamesAsync,
  getGameDetailAsync,
  createGameAsync,
  updateGameAsync,
} from './gamesActions';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },

  detail: {},

  fetch: {
    loading: false,
    error: '',
  },

  action: {
    loading: false,
    success: false,
    error: '',
  },
};

export const gamesReducer = handleActions(
  new Map([
    [
      getGamesAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      getGameDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      combineActions(
        createGameAsync.success,
        updateGameAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
  ]),
  initialState
);
