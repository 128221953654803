import * as auth from './auth/auth.service';
import * as dashboard from './dashboard/dashboard.service';
import * as games from './games/games.service';
import * as tournaments from './tournaments/tournaments.service';

export default {
  auth,
  dashboard,
  games,
  tournaments,
};
