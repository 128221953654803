import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
// import { renderRoutes } from 'react-router-config';

import { store, persistor } from './store/store';

import './App.scss';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() =>
  import('./containers/DefaultLayout')
);

// Pages
const Login = React.lazy(() => import('./views/Public/Login'));
const Activate = React.lazy(() => import('./views/Public/Activate'));
const Forgot = React.lazy(() => import('./views/Public/Forgot'));
const Reset = React.lazy(() => import('./views/Public/Reset'));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/activate"
                  name="Activation Page"
                  render={(props) => <Activate {...props} />}
                />
                <Route
                  exact
                  path="/password-reset"
                  name="Reset Password"
                  render={(props) => <Forgot {...props} />}
                />
                <Route
                  exact
                  path="/password-reset/confirm"
                  name="Reset Password"
                  render={(props) => <Reset {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <DefaultLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
