import { handleActions } from 'redux-actions';
import { REHYDRATE, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  authLoginAsyncAction,
  authLogoutAction,
  checkActivationKeyAsync,
  checkPasswordKeyAsync,
  requestForgotKeyAsync,
  resetPasswordAsync,
  submitActivationKeyAsync,
} from './authActions';

const initialState = {
  authenticated: true,
  token: '',
  profile: {},

  login: {
    loading: false,
    error: '',
  },

  activation: {
    data: {
      key: '',
      name: '',
    },
    submit: {
      loading: false,
      status: false,
    },
    loading: true,
    error: '',
  },

  forgot: {
    valid: {
      status: false,
      loading: true,
      error: '',
    },
    request: {
      loading: false,
      status: false,
    },
    submit: {
      status: false,
      loading: false,
      error: '',
    },
  },
};

export const authReducer = persistReducer(
  {
    key: 'auth',
    storage,
    whitelist: ['token', 'email'],
  },
  handleActions(
    new Map([
      [
        REHYDRATE,
        (state, action) => ({
          ...state,
          authenticated:
            action.payload && action.payload.token ? true : false,
          email:
            action.payload && action.payload.email
              ? action.payload.email
              : '',
        }),
      ],
      [
        authLoginAsyncAction.request,
        (state) => ({
          ...state,
          login: { ...initialState.login },
        }),
      ],
      [
        authLoginAsyncAction.success,
        (state, { payload }) => ({
          ...state,
          token: payload.accessToken,
          authenticated: true,
          email: payload.email,
        }),
      ],
      [
        authLoginAsyncAction.failure,
        (state, { payload }) => ({
          ...state,
          login: {
            loading: false,
            error: payload,
          },
        }),
      ],

      // Check Activation Key
      [
        checkActivationKeyAsync.request,
        (state) => ({
          ...state,
          activation: {
            ...initialState.activation,
            loading: true,
          },
        }),
      ],
      [
        checkActivationKeyAsync.success,
        (state, { payload }) => ({
          ...state,
          activation: {
            ...initialState.activation,
            loading: false,
            data: payload,
          },
        }),
      ],
      [
        checkActivationKeyAsync.failure,
        (state, { payload }) => ({
          ...state,
          activation: {
            ...initialState.activation,
            loading: false,
            error: payload,
          },
        }),
      ],

      // Submit Activation Key
      [
        submitActivationKeyAsync.request,
        (state) => ({
          ...state,
          activation: {
            ...initialState.activation,
            loading: false,
            submit: {
              ...initialState.activation.submit,
              loading: true,
            },
          },
        }),
      ],
      [
        submitActivationKeyAsync.success,
        (state, { payload }) => ({
          ...state,
          activation: {
            ...initialState.activation,
            loading: false,
            submit: {
              ...initialState.activation.submit,
              loading: true,
              status: true,
            },
          },
        }),
      ],
      [
        submitActivationKeyAsync.failure,
        (state, { payload }) => ({
          ...state,
          activation: {
            ...initialState.activation,
            loading: false,
            submit: {
              ...initialState.activation.submit,
              loading: false,
            },
          },
        }),
      ],

      // Request Reset password
      [
        requestForgotKeyAsync.request,
        (state) => ({
          ...state,
          forgot: {
            request: {
              ...initialState.forgot.request,
              loading: true,
            },
          },
        }),
      ],
      [
        requestForgotKeyAsync.success,
        (state, { payload }) => ({
          ...state,
          forgot: {
            request: {
              ...initialState.forgot.request,
              status: true,
            },
          },
        }),
      ],
      [
        requestForgotKeyAsync.failure,
        (state, { payload }) => ({
          ...state,
          forgot: {
            request: {
              ...initialState.forgot.request,
              status: false,
            },
          },
        }),
      ],

      // Check Reset Password
      [
        checkPasswordKeyAsync.request,
        (state) => ({
          ...state,
          forgot: {
            ...initialState.forgot,
            valid: {
              ...initialState.forgot.valid,
              loading: true,
            },
          },
        }),
      ],
      [
        checkPasswordKeyAsync.success,
        (state, { payload }) => ({
          ...state,
          forgot: {
            ...initialState.forgot,
            valid: {
              ...initialState.forgot.valid,
              status: true,
              loading: false,
            },
          },
        }),
      ],
      [
        checkPasswordKeyAsync.failure,
        (state, { payload }) => ({
          ...state,
          forgot: {
            ...initialState.forgot,
            valid: {
              ...initialState.forgot.request,
              status: false,
              loading: false,
              error: payload,
            },
          },
        }),
      ],

      // Reset Password Request
      [
        resetPasswordAsync.request,
        (state) => ({
          ...state,
          forgot: {
            ...state.forgot,
            submit: {
              ...initialState.forgot.submit,
              loading: true,
            },
          },
        }),
      ],
      [
        resetPasswordAsync.success,
        (state, { payload }) => ({
          ...state,
          forgot: {
            ...state.forgot,
            submit: {
              ...initialState.forgot.submit,
              status: true,
            },
          },
        }),
      ],
      [
        resetPasswordAsync.failure,
        (state, { payload }) => ({
          ...state,
          forgot: {
            ...state.forgot,
            submit: {
              ...initialState.forgot.submit,
              error: payload,
            },
          },
        }),
      ],

      [
        authLogoutAction,
        (state) => ({
          ...state,
          token: '',
          profile: {},
          email: '',
          authenticated: false,
        }),
      ],
    ]),
    initialState
  )
);
