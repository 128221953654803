import {
  authLoginAsyncAction,
  checkActivationKeyAsync,
  checkPasswordKeyAsync,
  requestForgotKeyAsync,
  resetPasswordAsync,
  submitActivationKeyAsync,
} from './authActions';
import { createEpic } from '../helpers';

export const authLoginEpic = createEpic(
  authLoginAsyncAction,
  'auth',
  'doLogin'
);

export const checkActivationKeyEpic = createEpic(
  checkActivationKeyAsync,
  'auth',
  'checkActivationKey'
);

export const submitActivationKeyEpic = createEpic(
  submitActivationKeyAsync,
  'auth',
  'submitActivationKey'
);

export const requestForgotKeyEpic = createEpic(
  requestForgotKeyAsync,
  'auth',
  'requestForgotKey'
);

export const checkPasswordKeyEpic = createEpic(
  checkPasswordKeyAsync,
  'auth',
  'checkForgotKey'
);

export const resetPasswordEpic = createEpic(
  resetPasswordAsync,
  'auth',
  'resetPassword'
);
