import {
  compose,
  combineReducers,
  createStore,
  applyMiddleware,
} from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';

import services from '../services';

import { authReducer } from './auth/authReducer';
import * as authEpics from './auth/authEpics';

import { dashboardReducer } from './dashboard/dashboardReducer';
import * as dashboardEpics from './dashboard/dashboardEpics';

import { gamesReducer } from './games/gamesReducer';
import * as gamesEpics from './games/gamesEpics';

import { tournamentReducer } from './tournaments/tournamentsReducer';
import * as tournamentEpics from './tournaments/tournamentsEpics';

let composeEnhancers;
if (process.env.NODE_ENV === 'production') {
  composeEnhancers = compose;
} else {
  composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const epicMiddleware = createEpicMiddleware({
  dependencies: services,
});

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  games: gamesReducer,
  tournaments: tournamentReducer,
});

const rootEpic = combineEpics(
  ...Object.values(authEpics),
  ...Object.values(dashboardEpics),
  ...Object.values(gamesEpics),
  ...Object.values(tournamentEpics)
);

export const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'production'
    ? composeEnhancers(applyMiddleware(epicMiddleware))
    : composeEnhancers(applyMiddleware(logger, epicMiddleware))
);
epicMiddleware.run(rootEpic);
export const persistor = persistStore(store);

//epicMiddleware.run(rootEpic);
