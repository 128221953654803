import { ajax } from 'rxjs/ajax';
import { isEmpty } from 'lodash';
import { stringify } from 'qs';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const api = (
  path,
  method = 'GET',
  payload,
  params,
  token = '',
  header = 'json'
) => {
  const options = {
    url: API_URL + path,
    method: method.toUpperCase(),
    headers: {},
  };

  if (!isEmpty(params)) {
    options['url'] =
      options['url'] + stringify(params, { addQueryPrefix: true });
  }

  if (payload) {
    options['body'] = payload;
  }

  if (token) {
    options['headers']['Authorization'] = 'Bearer ' + token;
  }

  if (header === 'json') {
    options['headers']['Content-Type'] = 'application/json';
  }
  return ajax(options);
};

export const download = (path, params, token = '') => {
  const options = {
    responseType: 'blob',
    params,
    headers: {},
  };
  let url = API_URL + path;

  if (token) {
    options['headers']['Authorization'] = 'Bearer ' + token;
  }

  return axios.get(url, options);
};
