import { api } from '../helpers';

export const doLogin = (payload) =>
  api('/auth/login', 'POST', {
    ...payload,
    type: 'COMMUNITY_LEADER',
  });

export const checkActivationKey = (payload) =>
  api('/community-leader/activate/check', 'POST', payload);

export const submitActivationKey = (payload) =>
  api('/community-leader/activate', 'POST', payload);

export const requestForgotKey = (payload) =>
  api('/community-leader/forgot/request', 'POST', payload);

export const checkForgotKey = (payload) =>
  api('/community-leader/forgot/check', 'POST', payload);

export const resetPassword = (payload) =>
  api('/community-leader/forgot', 'POST', payload);
