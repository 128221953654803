import { createEpic } from '../helpers';

import {
  getGamesAsync,
  createGameAsync,
  getGameDetailAsync,
  updateGameAsync,
  uploadGameImageAsync,
} from './gamesActions';

export const getGamesEpic = createEpic(
  getGamesAsync,
  'games',
  'getList',
  true
);
export const getGameDetailEpic = createEpic(
  getGameDetailAsync,
  'games',
  'detail',
  true
);
export const createGameEpic = createEpic(
  createGameAsync,
  'games',
  'create',
  true
);
export const updateGameEpic = createEpic(
  updateGameAsync,
  'games',
  'update',
  true
);
export const uploadGameImageEpic = createEpic(
  uploadGameImageAsync,
  'games',
  'imageUpload',
  true
);
