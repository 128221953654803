import { createEpic } from '../helpers';
import {
  getTournamentsAsync,
  getTournamentDetailAsync,
  createTournamentAsync,
  updateTournamentAsync,
  getCountryListAsync,
  uploadCoverImageAsync,
  uploadFeaturedImagesAsync,
  changeTournamentStatusAsync,
  uploadRulesPdfAsync,
  getFeaturedTournamentsAsync,
  removeTeamMemberAsync,
  removeParticipantAsync,
  removeTeamAsync,
  updateParticipantResultAsync,
  updateTeamResultAsync,
  getTournamentParticipantsAsync,
  getTournamentTeamsAsync,
  updateParticipantStatusAsync,
  updateTeamStatusAsync,
  getTournamentStatsAsync,
  getAllTeamsAsync,
  getTournamentForBannerAsync,
  sendReminderAsync,
  createBracketAsync,
  getBracketListAsync,
  getBracketDetailAsync,
  updateBracketAsync,
  deleteBracketAsync,
  seedBracketTeamAsync,
  updateTeamNameAsync,
  getTournamentTeamListAsync,
  getTournamentParticipantListAsync,
  updateTeamMemberInGameNameAsync,
  updateParticipantInGameNameAsync,
  updateMatchScoreAsync,
  startBracketAsync,
  tournamentCheckInAsync,
  getMatchIssuesAsync,
  updateMatchIssueStatusAsync,
  getMatchDetailAsync,
  getStandingDetailAsync,
  submitFFABracketScoreAsync,
  submitForfeitPlayerAsync,
  submitDoubleLossAsync,
  submitDrawAsync,
  submitDropParticipantAsync,
  uploadFFABracketScreenshotAsync,
  generateNextSwissRoundAsync,
  revertForfeitAsync,
} from './tournamentsActions';

export const getTournamentsEpic = createEpic(
  getTournamentsAsync,
  'tournaments',
  'getList',
  true
);

export const getTournamentDetailEpic = createEpic(
  getTournamentDetailAsync,
  'tournaments',
  'getDetail',
  true
);

export const createTournamentEpic = createEpic(
  createTournamentAsync,
  'tournaments',
  'create',
  true
);

export const updateTournamentEpic = createEpic(
  updateTournamentAsync,
  'tournaments',
  'update',
  true
);

export const getCountriesEpic = createEpic(
  getCountryListAsync,
  'tournaments',
  'getCountries',
  true
);

export const uploadCoverImage = createEpic(
  uploadCoverImageAsync,
  'tournaments',
  'uploadCoverImage',
  true
);

export const uploadFeaturedImages = createEpic(
  uploadFeaturedImagesAsync,
  'tournaments',
  'uploadFeaturedImages',
  true
);

export const changeStatusEpic = createEpic(
  changeTournamentStatusAsync,
  'tournaments',
  'changeStatus',
  true
);

export const uploadRulesPDF = createEpic(
  uploadRulesPdfAsync,
  'tournaments',
  'uploadRulesPDF',
  true
);

export const getFeaturedTournamentsEpic = createEpic(
  getFeaturedTournamentsAsync,
  'tournaments',
  'getFeatureTournaments'
);

export const removeTeamMemberEpic = createEpic(
  removeTeamMemberAsync,
  'tournaments',
  'removeTeamMember',
  true
);

export const removeParticipantEpic = createEpic(
  removeParticipantAsync,
  'tournaments',
  'removeParticipant',
  true
);

export const removeTeamEpic = createEpic(
  removeTeamAsync,
  'tournaments',
  'removeTeam',
  true
);

export const updateParticipantResultEpic = createEpic(
  updateParticipantResultAsync,
  'tournaments',
  'updateParticipantResult',
  true
);

export const updateTeamResultEpic = createEpic(
  updateTeamResultAsync,
  'tournaments',
  'updateTeamResult',
  true
);

export const getTournmaentParticipantsEpic = createEpic(
  getTournamentParticipantsAsync,
  'tournaments',
  'getTournamentParticipants',
  true
);

export const getTournmaentTeamsEpic = createEpic(
  getTournamentTeamsAsync,
  'tournaments',
  'getTournamentTeams',
  true
);

export const updateParticipantStatus = createEpic(
  updateParticipantStatusAsync,
  'tournaments',
  'updateParticipantStatus',
  true
);

export const updateTeamStatus = createEpic(
  updateTeamStatusAsync,
  'tournaments',
  'updateTeamStatus',
  true
);

export const getTournamentJoinedStats = createEpic(
  getTournamentStatsAsync,
  'tournaments',
  'getTournamentJoinedStats',
  true
);

export const getAllTeams = createEpic(
  getAllTeamsAsync,
  'tournaments',
  'getAllTeams',
  true
);

export const getTournamentForBannerEpic = createEpic(
  getTournamentForBannerAsync,
  'tournaments',
  'getTournamentForBanners',
  true
);

export const sendReminderEpic = createEpic(
  sendReminderAsync,
  'tournaments',
  'sendReminder',
  true
);

export const getTournamentTeamListEpic = createEpic(
  getTournamentTeamListAsync,
  'tournaments',
  'getTournamentTeamList',
  true
);

export const getTournamentParticipantListEpic = createEpic(
  getTournamentParticipantListAsync,
  'tournaments',
  'getTournamentParticipantList',
  true
);

export const createBracketEpic = createEpic(
  createBracketAsync,
  'tournaments',
  'createBracket',
  true
);

export const getBracketListEpic = createEpic(
  getBracketListAsync,
  'tournaments',
  'getBracketList',
  true
);

export const getBracketDetailEpic = createEpic(
  getBracketDetailAsync,
  'tournaments',
  'getBracketDetail',
  true
);

export const updateBracketEpic = createEpic(
  updateBracketAsync,
  'tournaments',
  'updateBracket',
  true
);

export const deleteBracketEpic = createEpic(
  deleteBracketAsync,
  'tournaments',
  'deleteBracket',
  true
);

export const seedBracketTeamEpic = createEpic(
  seedBracketTeamAsync,
  'tournaments',
  'seedBracketTeam',
  true
);

export const updateTeamMemberInGameNameEpic = createEpic(
  updateTeamMemberInGameNameAsync,
  'tournaments',
  'updateTeamMemberInGameName',
  true
);

export const updateTeamNameEpic = createEpic(
  updateTeamNameAsync,
  'tournaments',
  'updateTeamName',
  true
);

export const updateParticipantInGameNameEpic = createEpic(
  updateParticipantInGameNameAsync,
  'tournaments',
  'updateParticipantInGameName',
  true
);

export const updateMatchScoreEpic = createEpic(
  updateMatchScoreAsync,
  'tournaments',
  'updateMatchScore',
  true
);

export const startBracketEpic = createEpic(
  startBracketAsync,
  'tournaments',
  'startBracket',
  true
);

export const tournamentCheckInEpic = createEpic(
  tournamentCheckInAsync,
  'tournaments',
  'tournamentCheckIn',
  true
);

export const getMatchIssuesEpic = createEpic(
  getMatchIssuesAsync,
  'tournaments',
  'getMatchIssues',
  true
);

export const updateMatchIssueStatusEpic = createEpic(
  updateMatchIssueStatusAsync,
  'tournaments',
  'updateMatchIssueStatus',
  true
);

export const getMatchDetailEpic = createEpic(
  getMatchDetailAsync,
  'tournaments',
  'getMatchDetail',
  true
);

export const getStandingDetailEpic = createEpic(
  getStandingDetailAsync,
  'tournaments',
  'getStandingDetail',
  true
);

export const submitFFABracketScoreEpic = createEpic(
  submitFFABracketScoreAsync,
  'tournaments',
  'submitFFABracketScore',
  true
);

export const submitForfeitPlayerEpic = createEpic(
  submitForfeitPlayerAsync,
  'tournaments',
  'submitForfeitPlayer',
  true
);

export const submitDoubleLossEpic = createEpic(
  submitDoubleLossAsync,
  'tournaments',
  'submitDoubleLoss',
  true
);

export const submitDrawEpic = createEpic(
  submitDrawAsync,
  'tournaments',
  'submitDraw',
  true
);

export const submitDropParticipantEpic = createEpic(
  submitDropParticipantAsync,
  'tournaments',
  'submitDropParticipant',
  true
);

export const uploadFFABracketScreenshotEpic = createEpic(
  uploadFFABracketScreenshotAsync,
  'tournaments',
  'uploadFFABracketScreenshot',
  true
);

export const generateNextSwissRoundEpic = createEpic(
  generateNextSwissRoundAsync,
  'tournaments',
  'generateNextSwissRound',
  true
);

export const revertForfeitEpic = createEpic(
  revertForfeitAsync,
  'tournaments',
  'revertForfeit',
  true
);
