import {
  getTournamentsAsync,
  getTournamentDetailAsync,
  createTournamentAsync,
  updateTournamentAsync,
  getCountryListAsync,
  uploadCoverImageAsync,
  uploadFeaturedImagesAsync,
  changeTournamentStatusAsync,
  uploadRulesPdfAsync,
  getFeaturedTournamentsAsync,
  removeTeamAsync,
  removeTeamMemberAsync,
  removeParticipantAsync,
  updateTeamResultAsync,
  updateParticipantResultAsync,
  getTournamentParticipantsAsync,
  getTournamentTeamsAsync,
  updateParticipantStatusAsync,
  updateTeamStatusAsync,
  getTournamentStatsAsync,
  getAllTeamsAsync,
  getTournamentForBannerAsync,
  sendReminderAsync,
  getTournamentTeamListAsync,
  getTournamentParticipantListAsync,
  createBracketAsync,
  getBracketListAsync,
  getBracketDetailAsync,
  updateBracketAsync,
  deleteBracketAsync,
  seedBracketTeamAsync,
  updateTeamMemberInGameNameAsync,
  updateTeamNameAsync,
  updateParticipantInGameNameAsync,
  updateMatchScoreAsync,
  startBracketAsync,
  getMatchIssuesAsync,
  updateMatchIssueStatusAsync,
  getMatchDetailAsync,
  getStandingDetailAsync,
  submitFFABracketScoreAsync,
  submitForfeitPlayerAsync,
  submitDoubleLossAsync,
  submitDrawAsync,
  submitDropParticipantAsync,
  uploadFFABracketScreenshotAsync,
  generateNextSwissRoundAsync,
  revertForfeitAsync,
} from './tournamentsActions';
import { combineActions, handleActions } from 'redux-actions';

const initialState = {
  list: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  bracketList: [],
  activeTournaments: [],
  allTeams: {},
  allTeamsDetail: [],
  allParticipantsDetail: [],
  joinedStats: {},
  detail: {},
  bracketDetail: null,
  participants: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  matchIssues: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  matchDetail: {},
  standingDetail: {},
  teams: {
    items: [],
    meta: {
      totalPages: 0,
    },
  },
  featuredCount: 0,
  countries: [],
  fetch: {
    loading: false,
    error: '',
  },
  action: {
    loading: false,
    success: false,
    uploaded: false,
    changed: false,
    error: '',
  },
  updateAction: {
    loading: false,
    success: false,
    uploaded: false,
    changed: false,
    error: '',
  },
};
export const tournamentReducer = handleActions(
  new Map([
    [
      getTournamentsAsync.success,
      (state, { payload }) => ({
        ...state,
        list: payload,
      }),
    ],
    [
      getBracketListAsync.success,
      (state, { payload }) => ({
        ...state,
        bracketList: payload,
      }),
    ],
    [
      getBracketDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        bracketDetail: payload,
      }),
    ],
    [
      getTournamentDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        detail: payload,
      }),
    ],
    [
      getCountryListAsync.success,
      (state, { payload }) => ({
        ...state,
        countries: payload,
      }),
    ],
    [
      getFeaturedTournamentsAsync.success,
      (state, { payload }) => ({
        ...state,
        featuredCount: payload.length,
      }),
    ],
    [
      getTournamentForBannerAsync.success,
      (state, { payload }) => ({
        ...state,
        activeTournaments: payload,
      }),
    ],
    [
      getTournamentParticipantsAsync.success,
      (state, { payload }) => ({
        ...state,
        participants: payload,
      }),
    ],
    [
      getTournamentTeamsAsync.success,
      (state, { payload }) => ({
        ...state,
        teams: payload,
      }),
    ],
    [
      getTournamentStatsAsync.success,
      (state, { payload }) => ({
        ...state,
        stats: payload,
      }),
    ],
    [
      getAllTeamsAsync.success,
      (state, { payload }) => ({
        ...state,
        allTeams: payload,
      }),
    ],
    [
      getTournamentTeamListAsync.success,
      (state, { payload }) => ({
        ...state,
        allTeamsDetail: payload,
      }),
    ],
    [
      getTournamentParticipantListAsync.success,
      (state, { payload }) => ({
        ...state,
        allParticipantsDetail: payload,
      }),
    ],
    [
      updateBracketAsync.success,
      (state, { payload }) => ({
        ...state,
        updateAction: {
          ...initialState.updateAction,
          success: payload,
        },
      }),
    ],
    [
      getMatchIssuesAsync.success,
      (state, { payload }) => ({
        ...state,
        matchIssues: payload,
      }),
    ],
    [
      getMatchDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        matchDetail: payload,
      }),
    ],
    [
      getStandingDetailAsync.success,
      (state, { payload }) => ({
        ...state,
        standingDetail: payload,
      }),
    ],
    [
      combineActions(
        createTournamentAsync.success,
        updateTournamentAsync.success,
        removeTeamAsync.success,
        removeTeamMemberAsync.success,
        removeParticipantAsync.success,
        updateParticipantStatusAsync.success,
        updateTeamResultAsync.success,
        updateParticipantResultAsync.success,
        updateTeamStatusAsync.success,
        sendReminderAsync.success,
        createBracketAsync.success,
        deleteBracketAsync.success,
        seedBracketTeamAsync.success,
        updateTeamMemberInGameNameAsync.success,
        updateTeamNameAsync.success,
        updateParticipantInGameNameAsync.success,
        updateMatchScoreAsync.success,
        startBracketAsync.success,
        submitFFABracketScoreAsync.success,
        submitForfeitPlayerAsync.success,
        submitDoubleLossAsync.success,
        submitDrawAsync.success,
        submitDropParticipantAsync.success,
        generateNextSwissRoundAsync.success,
        revertForfeitAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          success: payload,
        },
      }),
    ],
    [
      combineActions(
        updateParticipantStatusAsync.failure,
        updateTeamStatusAsync.failure,
        updateTeamMemberInGameNameAsync.failure,
        updateParticipantInGameNameAsync.failure,
        seedBracketTeamAsync.failure,
        updateTeamMemberInGameNameAsync.failure,
        updateTeamNameAsync.failure,
        updateParticipantInGameNameAsync.failure,
        updateMatchScoreAsync.failure,
        startBracketAsync.failure,
        submitFFABracketScoreAsync.failure,
        submitForfeitPlayerAsync.failure,
        submitDoubleLossAsync.failure,
        submitDrawAsync.failure,
        submitDropParticipantAsync.failure,
        generateNextSwissRoundAsync.failure,
        revertForfeitAsync.failure
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          error: payload,
        },
      }),
    ],
    [
      combineActions(
        changeTournamentStatusAsync.success,
        updateMatchIssueStatusAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          changed: payload,
        },
      }),
    ],
    [
      combineActions(
        uploadCoverImageAsync.success,
        uploadFeaturedImagesAsync.success,
        uploadRulesPdfAsync.success,
        uploadFFABracketScreenshotAsync.success
      ),
      (state, { payload }) => ({
        ...state,
        action: {
          ...initialState.action,
          uploaded: payload,
        },
      }),
    ],
  ]),
  initialState
);
