import { handleActions } from 'redux-actions';
import { getDashboardStatsAsync } from './dashboardActions';

const initialState = {
  stats: {},
  fetch: {
    loading: false,
    error: '',
  },

  action: {
    loading: false,
    success: false,
    error: '',
  },
};

export const dashboardReducer = handleActions(
  new Map([
    [
      getDashboardStatsAsync.success,
      (state, { payload }) => ({
        ...state,
        stats: payload,
      }),
    ],
  ]),
  initialState
);
