import { createAction } from 'redux-actions';
import { createAsyncAction } from '../helpers';

export const authLoginAsyncAction = createAsyncAction('AUTH_LOGIN');

export const authLogoutAction = createAction('AUTH_LOGOUT');

export const checkActivationKeyAsync = createAsyncAction(
  'AUTH_CHECK_KEY'
);

export const submitActivationKeyAsync = createAsyncAction(
  'AUTH_CHECK_SUBMIT'
);

export const requestForgotKeyAsync = createAsyncAction(
  'FORGOT_REQUEST'
);

export const checkPasswordKeyAsync = createAsyncAction(
  'FORGOT_CHECK_KEY'
);

export const resetPasswordAsync = createAsyncAction('FORGOT_SUBMIT');
